// api模块
import config from "@/common/config"
import tool from "@/common/tool"
const urls = {
  openid: "https://server.medflying.com/api/common/wx/openid",//openid
  jssign: 'https://server.medflying.com/api/common/wx/jssign',//获取js SDK  
  oauth: '/wx/set_cookie_openid',//验证
  uploads: {
    uploadBase64: "https://files.medflying.com/file_system/filesbase64",
    uploadFile: "http://files.medflying.com/file_system/files",
  },
  user: {
    login: "/user/login", // 登录
    info: "/user/info", // 用户信息,修改等
    match: "/user/match",//智能匹配
    list: "/user/list", // 查询所有专家
    audit: "/user/audit_job", // 人工审核
    executorLogin: "/admin/login",// 执行人登录
    executorMeetList: "/admin/meet", // 执行人会议列表
    changePassword: "/user/change_password",//修改密码
    passwdSms: "/user/passwd_sms",//获取验证码
    users: "/forum/users",
    guest: "/user/guest", // 是否第一次进入系统
  },
  live: {
    list: '/user/meet',// 我的会议
    all: "/forum/get_post",//帖子列表
    create: "/forum/add_post",//创建帖子
    update: "/forum/patch_post",//修改帖子
    detail: "/forum/post_detail",//帖子详情
    detailAll: "/forum/post_detail_all",//所有人都可以看的那种
    mode: "/forum/free_post",//更改模式
    replay: "/forum/reply_post",//回复帖子
    agree: "/forum/post_invite_agree",//同意参会
    invite: "/forum/post_invite",//邀请参会
    inviteOther: "/forum/patch_invite",//修改邀请专家
    updateDoctor: "/forum/invite_compulsion",//修改会议专家
    updateMeetMode: "/forum/match_post",// 修改会议模式
    chatList: "/forum/room/chat", //聊天记录列表
    liveList: "/forum/live", //聊天记录列表
    join: "/forum/join_post", // 加入会议
    playList: "/forum/live", // 直播列表
    sign: "/forum/live_sign", // 直播签到
    submit: "/forum/finish_group", // 提交审核
    refuse: "/forum/reject_post", // 拒绝会议
    controlbook: '/meet/controlbook',//操作手册
    sendMeetTimeChat: '/msg_send_meet_chat',// 管理员 会议提醒 时长
    sendMeetUserChat: '/msg_send_meet_chat_user',// 管理员 人员不足提醒 时长
    signUser: "/forum/live_user", // 签到人数
    sendCommon: "/msg_send_common", // 通用模板
    meetTime: config.host + "/back/forum/meet_time", // 修改会议时间
    signTime: "/forum/live_info", // 签到次数
    exit: "/forum/exit_group", // 退出会议
    agreeExit: "/forum/post_invite_on_hold",//  统一退出
    refuseExit: "/forum/post_invite_on_hold",// 邀请退出
    changeInvite: "/forum/post_invite_five",// 改变邀请
    customerInfo: "/forum/customer_info",// 客服
    matchSignDoctor: "/forum/match_sign_doctor",// 签到匹配
    meetNumWarning: "/forum/meet_num_warning",// 会议饱和度
  },
  topicSelection: {
    list: '/forum/get_selection_post',//  评选帖子列表
    detail: '/forum/selection_post_detail',// 评选贴子详细信息
    post_like: '/forum/selection_post_like', //评选贴子点赞   
    selection_check: "/forum/selection_check",  //评选确认
  },
  agreement: {
    meetSign: "/user/doctor_sign",//签名
    signInfo: "/user/sign_doctor_info",//用户信息
    remark: '/forum/remark' //评价反馈
  },
  all: {
    hospital: "/user/hospital"
  },
  hospital: {
    areaList: "/apply/area_list",//地区列表 
    hospitalList: "/apply/hospital_list",//医院列表
    managerList: "/apply/manager_list",//经理列表 
    addApply: "/apply/add_apply",//医生申请
    addDetail: "/apply/apply_detail",//医生详情
    patchApply: "/apply/patch_apply",//医生修改
    detail_by_phone: "/apply/apply_detail_by_phone",//根据医生电话获取报名详情
    detail_by_phone_new: "/apply/apply_detail_by_phone_new",//根据医生电话获取报名详情(变更)
    login: `${config.host}/back/api/login`,//报名系统登录
    code: `${config.host}/back/api/sms`,//报名系统验证码
    list: `${config.host}/back/apply/apply_list`, //报名系统-审批列表
    delete: `${config.host}/back/apply/delete_apply`,//报名系统-审批删除
    detail: `${config.host}/back/apply/apply_detail`,//报名系统-审批详情
    update: `${config.host}/back/apply/patch_apply`,//报名系统-审批修改
    audit: `${config.host}/back/apply/audit_apply`,//报名系统-审批审核
    exportAreaList: `${config.host}/back/area/export_area_list`,//报名系统-导出区域报名统计
    changePerson: "/apply/change_person",//报名系统-变更
  },
}

let host = tool.isLocal() ? config.localhost : config.host;
for (let key in urls) {
  if (typeof urls[key] == "object") {
    for (let i in urls[key]) {
      if (urls[key][i].includes("http")) continue;

      urls[key][i] = `${host}${config.urlPrefix}${urls[key][i]}`;
    }
    continue
  }
  if (urls[key].includes("http")) continue;
  urls[key] = `${host}${config.urlPrefix}${urls[key]}`;
}
export default urls;