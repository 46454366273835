
import tools from "@/common/tool"
import config from "@/common/config"
import urls from "@/common/urls"
import axios from "@/common/axios"
import store from "@/store"
export const adminRouterBefore = (to, from, next) => {
  if (store.state.common.userInfo.name) {
    jumpHome(to, from, next)
  } else {
    if (store.state.common.userId) {
      getUserInfo(to, from, next)
    } else {
      let loginInfo = tools.getStorage("login", localStorage) || {}
      if (loginInfo.phone && loginInfo.passwd) {
        axios.post(urls.user.login, loginInfo).then(res => {
          tools.setStorage("login", loginInfo)
          store.dispatch("setItem", { userId: res.id })
          getUserInfo(to, from, next)
        }).catch(() => {
          next('/login')
        })
        return;
      }
      next('/login')
    }
  }
}
export const reportBefore = (to, from, next) => {
  if (window.location.origin === config.host) {
    window.location.href = window.location.href.replace(config.host, config.report);
  }
  //  else {
  //   next();
  // }
  let jumpPath = ['hospitalInfo', 'updateHospitalInfo', 'reportLogin', 'changeHospitalInfo']
  if (jumpPath.includes(to.name)) { next(); return; }

  let data = tools.getStorage("loginReportData", localStorage) || {};
  if (!data?.phone) { next("/reportLogin"); return; }
  axios
    .post(urls.hospital.login, data)
    .then((res) => {
      store.dispatch("setItem", { userReportInfo: res });
      next()
    })
    .catch(() => {
      next('/reportLogin')
      return
    });


}
const jumpHome = (to, from, next) => {
  if (to.name == "home" && !tools.getStorage("jumpHome") && store.state.common.userInfo.invite_count > 0) {
    tools.setStorage("jumpHome", 1)
    next("/myMeetList")
  } else {
    next();
  }
}
export const executorRouterBefore = (to, from, next) => {
  if (store.state.common.executorInfo.name) {
    next()
  } else {
    let loginInfo = tools.getStorage("executorLogin", localStorage) || {}
    if (loginInfo.phone && loginInfo.passwd) {
      axios.post(urls.user.executorLogin, loginInfo).then(res => {
        tools.setStorage("executorLogin", loginInfo)
        store.dispatch("setItem", { executorInfo: res || {} })
        next()
      }).catch(() => {
        next('/executorLogin')
      })
      return;
    }
    next('/executorLogin')
  }
}
const getUserInfo = (to, from, next) => {
  axios.get(urls.user.info).then(res => {
    store.dispatch('setItem', { userInfo: res.data })
    jumpHome(to, from, next)
  }).catch(() => {
    next('/login')
  })
}

// export const liveRouterBefore = (to, from, next) => {
//   if (store.state.common.signInfo.name) {
//     next()
//   } else {
//     let url = tools.getURL(urls.live.sign, { id: to.query.id, device_code: encodeURI(tools.getStorage("device-code", localStorage) || "") })
//     axios.get(url).then(res => {
//       tools.setStorage("device-code", res.data.device_code, localStorage)


//       if (res.data.status) {
//         store.dispatch("setItem", { signInfo: res.data })
//         next()
//       }
//       else {

//         next({ name: "sign", path: "/sign", query: { ...to.query } })
//       }
//     }).catch(res => {
//       next("/permission")
//     })
//   }
// }
export const liveRouterBefore = async (to, from, next) => {
  let signInfo = store.state.common.signInfo[to.query.id] || {};
  if (to.query.type) {
    next()
    return;
  }
  if (signInfo.openid) {
    if (to.name == "live") next()
    else next({
      path: `/live`,
      query: to.query
    })
    return;
  }
  let headers = { openid: tools.getCookie("opendid") || "" };
  let tempOpenid = tools.getCookie(
    `${to.query.id}||openid`
  );
  if (tempOpenid) {
    headers.openid = tempOpenid;
  } else {
    headers.openid = tools.getCookie("openid");
  }
  let url = tools.getURL(urls.live.sign, { id: to.query.id, device_code: encodeURI(tools.getStorage("device-code", localStorage) || "") })
  let response = await axios.get(url, headers)
  //直播结束 弹提示 直播已结束，感谢观看
  if (response.data.tx_meet_status == 20) {
    next({
      path: `/endLive`
    })
  }
  //0 没签需要填写消息 1 本场会签了(不需要再跳签到)  2 有签到消息需要确认(跳签到页面回显)
  if (response.data && response.data.openid && response.data.status == 1) {
    signInfo[to.query.id] = response.data
    store.dispatch("setItem", { signInfo })
    tools.setStorage("device-code", response.data.device_code, localStorage)
    next({
      path: `/live`,
      query: to.query
    })
  } else {
    if (to.name == 'sign') next();
    else next({
      path: `/sign`,
      query: to.query
    })
  }
}
export const commonRouterBefore = (to, from, next) => {
  next()
}
export const guestRouteBefore = (to, from, next) => {
  console.log(store.state.common.jumpGuest)
  if (store.state.common.guestCode == 1 || tools.getStorage("jumpGuest")) {
    next();
    return;
  } else if (store.state.common.guestCode == 2) {
    next("/home")
    return;
  } else {
    axios.get(urls.user.guest).then(res => {
      if (res.data) {
        store.dispatch("setItem", { guestCode: 2 })
        next("/home")
      } else {
        store.dispatch("setItem", { guestCode: 1 })
        next();
      }
    }).catch(() => {
      store.dispatch("setItem", { guestCode: 1 })
      next()
    })
  }
}