// 使用方式 this.$store.dispatch('setItem',{key:value})
// 使用方式 this.$store.commit('setItem',{key:value})
const modules = {
  common: {
    state() {
      return {
        isConnect: true,
        userInfo: {}, // 用户信息
        adminInfo: {},
        signInfo: {}, // 签到信息
        executorInfo: {}, // 执行人信息
        userReportInfo: {},//报名登录信息
        userId: 0,
        jumpGuest: false, // 是否直接跳转嘉宾
        guestCode: 0, // 0 未验证 1 游客，2 用户
      }
    },
    mutations: {
      setItem(state, data) {
        for (let key in data) {
          state[key] = data[key];
        }
      }
    },
    actions: {
      setItem(context, data) {
        context.commit("setItem", data)
      }
    }
  }
}

export default modules;