import { createRouter, createWebHashHistory, } from "vue-router"
import weixin from "@/common/weixin"
import tool from "@/common/tool"
import config from "@/common/config"
import { adminRouterBefore, liveRouterBefore, commonRouterBefore, executorRouterBefore, guestRouteBefore, reportBefore } from './routerBefore'
const commonRoutes = [
  {
    path: "/login",
    meta: { title: "登录" },
    beforeEnter: commonRouterBefore,
    name: "login",
    component: () => import(/* webpackChunkName: "login", webpackPrefetch: true */ "@/components/page/user/login.vue")
  },
  {
    path: "/executorLogin",
    meta: { title: "登录" },
    beforeEnter: commonRouterBefore,
    name: "executorLogin",
    component: () => import(/* webpackChunkName: "executorLogin", webpackPrefetch: true */ "@/components/page/user/executorLogin.vue")
  },

  {
    path: "/forgotPass",
    meta: { title: "修改密码" },
    beforeEnter: commonRouterBefore,
    name: "forgotPass",
    component: () => import(/* webpackChunkName: "forgotPass", webpackPrefetch: true */ "@/components/page/user/forgotPass.vue")
  },
  {
    path: "/popup",
    meta: { title: "弹窗" },
    beforeEnter: commonRouterBefore,
    name: "popup",
    component: () => import(/* webpackChunkName: "popup", webpackPrefetch: true */ "@/components/unit/Popup.vue")
  },
  {
    path: "/sign",
    meta: { title: "签到" },
    name: "sign",
    component: () => import(/* webpackChunkName: "sign", webpackPrefetch: true */ "@/components/page/user/signIn.vue")
  },
  {
    path: "/instruction",
    meta: { title: "操作指南" },
    name: "instruction",
    component: () => import(/* webpackChunkName: "instruction", webpackPrefetch: true */ "@/components/page/my/instruction.vue")
  },
  {
    path: "/index",
    meta: { title: "大厅模式" },
    name: "index",
    beforeEnter: guestRouteBefore,
    component: () => import(/* webpackChunkName: "index", webpackPrefetch: true */ "@/components/page/home/index.vue")
  },
  {
    path: "/topicSelectionList",
    meta: { title: "肿瘤免疫临床实践热门话题评选" },
    name: "topicSelectionList",
    component: () => import(/* webpackChunkName: "topicSelectionList", webpackPrefetch: true */ "@/components/page/topicSelection/topicSelectionList.vue")
  },
  {
    path: "/topicSelectionDetail",
    meta: { title: "肿瘤免疫临床实践热门话题评选" },
    name: "topicSelectionDetail",
    component: () => import(/* webpackChunkName: "topicSelectionDetail", webpackPrefetch: true */ "@/components/page/topicSelection/topicSelectionDetail.vue")
  }, {
    path: "/endLive",
    meta: { title: "直播结束提醒" },
    name: "endLive",
    component: () => import(/* webpackChunkName: "endLive", webpackPrefetch: true */ "@/components/page/user/endLive.vue")
  },
  {
    path: "/endLive",
    meta: { title: "直播结束提醒" },
    name: "endLive",
    component: () => import(/* webpackChunkName: "endLive", webpackPrefetch: true */ "@/components/page/user/endLive.vue")
  },
]

const adminRoutes = [

  {
    path: "/home",
    meta: { title: "" },
    beforeEnter: adminRouterBefore,
    name: "home",
    component: () => import(/* webpackChunkName: "home", webpackPrefetch: true */ "@/components/page/home/index.vue")
  },


  {
    path: "/applyMeet",
    meta: { title: "" },
    beforeEnter: adminRouterBefore,
    name: "applyMeet",
    component: () => import(/* webpackChunkName: "applyMeet", webpackPrefetch: true */ "@/components/page/meet/applyMeet.vue")
  },
  {
    path: "/publish",
    meta: { title: "发布成功" },
    beforeEnter: adminRouterBefore,
    name: "publish",
    component: () => import(/* webpackChunkName: "publish", webpackPrefetch: true */ "@/components/page/meet/publish.vue")
  },
  {
    path: "/inviteDoctor",
    meta: { title: "邀请" },
    beforeEnter: adminRouterBefore,
    name: "inviteDoctor",
    component: () => import(/* webpackChunkName: "inviteDoctor", webpackPrefetch: true */ "@/components/page/meet/inviteDoctor.vue")
  },
  {
    path: "/myMeetList",
    meta: { title: "我的会议" },
    beforeEnter: adminRouterBefore,
    name: "myMeetList",
    component: () => import(/* webpackChunkName: "myMeetList", webpackPrefetch: true */ "@/components/page/meet/myMeetList.vue")
  },
  {
    path: "/chatRoom",
    beforeEnter: adminRouterBefore,
    meta: {},
    name: "chatRoom",
    component: () => import(/* webpackChunkName: "chatRoom", webpackPrefetch: true */ "@/components/page/chat/chatRoom.vue")
  },
  {
    path: "/chatRoomList",
    beforeEnter: adminRouterBefore,
    meta: {},
    name: "chatRoomList",
    component: () => import(/* webpackChunkName: "chatRoomList", webpackPrefetch: true */ "@/components/page/chat/chatRoomList.vue")
  },
  {
    path: "/detail", beforeEnter: adminRouterBefore,
    meta: { title: "详情" },
    name: "detail",
    component: () => import(/* webpackChunkName: "detail", webpackPrefetch: true */ "@/components/page/meet/detail.vue")
  },
]

const adminChatRoute = [
  {
    path: "/executorRoom",
    beforeEnter: executorRouterBefore,
    meta: {},
    name: "executorRoom",
    component: () => import(/* webpackChunkName: "executorRoom", webpackPrefetch: true */ "@/components/page/chat/chatRoom.vue")
  },
  {
    path: "/chatRoomList",
    beforeEnter: executorRouterBefore,
    meta: {},
    name: "chatRoomList",
    component: () => import(/* webpackChunkName: "chatRoomList", webpackPrefetch: true */ "@/components/page/chat/chatRoomList.vue")
  },
]

const liveRoutes = [
  {
    path: "/live",
    beforeEnter: liveRouterBefore,
    name: "live",
    component: () => import(/* webpackChunkName: "live", webpackPrefetch: true */ "@/components/page/live/live.vue")
  },
]
const ignoreRoutes = [

  {
    path: "/livePlatform",
    meta: { title: "直播" },
    name: "livePlatform",
    component: () => import(/* webpackChunkName: "livePlatform", webpackPrefetch: true */ "@/components/page/live/livePlatform.vue")
  },
  {
    path: "/page404",
    name: "page404",
    component: () => import(/* webpackChunkName: "page404", webpackPrefetch: true */ "@/components/common/page404.vue")
  },
  {
    path: "/jump",
    name: "jump",
    component: () => import(/* webpackChunkName: "jump", webpackPrefetch: true */ "@/components/main/jump.vue")
  },
  {
    path: "/permission",
    name: "permission",
    component: () => import(/* webpackChunkName: "permission", webpackPrefetch: true */ "@/components/main/permission.vue")
  },
  {
    path: "/userAgreement",
    meta: { title: "用户协议" },
    name: "userAgreement",
    component: () => import(/* webpackChunkName: "userAgreement", webpackPrefetch: true */ "@/components/page/agreement/userAgreement.vue")
  }
  ,
  {
    path: "/privacyAgreement",
    meta: { title: "隐私协议" },
    name: "privacyAgreement",
    component: () => import(/* webpackChunkName: "privacyAgreement", webpackPrefetch: true */ "@/components/page/agreement/privacyAgreement.vue")
  },
  {
    path: "/signPrivacyAgreement",
    meta: { title: "隐私协议" },
    name: "signPrivacyAgreement",
    component: () => import(/* webpackChunkName: "signPrivacyAgreement", webpackPrefetch: true */ "@/components/page/agreement/signPrivacyAgreement.vue")
  },
  {
    path: "/agreement",
    meta: { title: "劳务协议" },
    name: "agreement",
    component: () => import(/* webpackChunkName: "agreement", webpackPrefetch: true */ "@/components/page/agreement/agreement.vue")
  },
  {
    path: "/agreementShare",
    name: "agreementShare",
    component: () => import(/* webpackChunkName: "agreement", webpackPrefetch: true */ "@/components/page/agreement/agreement.vue")
  },
  {
    path: "/doctorInfo",
    meta: { title: "专家信息" },
    beforeEnter: adminRouterBefore,
    name: "doctorInfo",
    component: () => import(/* webpackChunkName: "doctorInfo", webpackPrefetch: true */ "@/components/page/home/doctorInfo.vue")
  },
  // 
  {
    path: "/reportLogin",
    meta: { title: "报名系统-登录" },
    name: "reportLogin",
    component: () => import(/* webpackChunkName: "reportLogin", webpackPrefetch: true */ "@/components/page/hospital/login.vue")
  },
  {
    path: "/hospitalInfo",
    meta: { title: "报名系统-新增" }, beforeEnter: reportBefore,
    name: "hospitalInfo",
    component: () => import(/* webpackChunkName: "hospitalInfo", webpackPrefetch: true */ "@/components/page/hospital/hospitalInfo.vue")
  },
  {
    path: "/updateHospitalInfo",
    meta: { title: "报名系统-修改" }, beforeEnter: reportBefore,
    name: "updateHospitalInfo",
    component: () => import(/* webpackChunkName: "updateHospitalInfo", webpackPrefetch: true */ "@/components/page/hospital/updateHospitalInfo.vue")
  },
  {
    path: "/changeHospitalInfo",
    meta: { title: "报名系统-变更" }, beforeEnter: reportBefore,
    name: "changeHospitalInfo",
    component: () => import(/* webpackChunkName: "changeHospitalInfo", webpackPrefetch: true */ "@/components/page/hospital/changeHospitalInfo.vue")
  },
  {
    path: "/reportList",
    meta: { title: "报名系统-审批列表" }, beforeEnter: reportBefore,
    name: "reportList",
    component: () => import(/* webpackChunkName: "reportList", webpackPrefetch: true */ "@/components/page/hospital/reportList.vue")
  },
  {
    path: "/signUpInfo",
    meta: { title: "报名系统-报名详情" },
    beforeEnter: reportBefore,
    name: "signUpInfo",
    component: () => import(/* webpackChunkName: "signUpInfo", webpackPrefetch: true */ "@/components/page/hospital/signUpInfo.vue")
  },
  {
    path: "/equity",
    meta: { title: "报名系统-修改权益" }, beforeEnter: reportBefore,
    name: "equity",
    component: () => import(/* webpackChunkName: "equity", webpackPrefetch: true */ "@/components/page/hospital/equity.vue")
  },
]
const routes = [].concat(commonRoutes, ignoreRoutes, liveRoutes, adminRoutes, adminChatRoute);
// const routes =  []
const router = createRouter({
  history: createWebHashHistory(),
  routes
});

const ignorePages = ignoreRoutes.map(item => item.name) // 不需要验证的页面
router.beforeEach((to, from, next) => {
  tool.saveVersion()
  if (ignorePages.includes(to.name)) { next(); return; }
  // weixin.init()
  document.title = config.title
  // if (to.meta.title !== undefined) {
  //   document.title = !to.meta.title ? config.title : `${config.title}-${to.meta.title}`
  // }

  tool.isLocal() ? development(to, from, next) : produce(to, from, next);
})
async function development(to, from, next) {
  tool.setCookie('openid', 'oUOaC6bG6E-nNGQO_LxF8aJbjVng')
  inSystem(to, from, next)
}
async function produce(to, from, next) {
  weixin.init()
  let openid = tool.getCookie("openid") || "";
  if (!openid) {
    next({ name: "jump", path: "/jump", query: { path: to.path, ...to.query } })
    return;
  }
  inSystem(to, from, next)
}
async function inSystem(to, from, next) {
  next()
}
export default router;
