import { ElMessage } from 'element-plus'
import { ElLoading } from 'element-plus'

const setTips = (data, type, time = 3000, isCenter = true) => {
  let text = data.text;
  let message = {
    message: text,
    center: true,
    duration: time,
    type
  }
  if (isCenter) {
    message.offset = document.body.clientHeight / 2 - 24
  }
  ElMessage(message);
}
const warning = (data, time) => {
  setTips(data, 'warning', time);
}
const error = (data, time) => {
  setTips(data, 'error', time);
}
const info = (data, time) => {
  setTips(data, 'info', time);
}
const success = (data, time) => {
  setTips(data, 'success', time);
}
const loading = (data = {}, time = 2000) => {
  let loading = ElLoading.service({
    lock: true,
    text: 'Loading',
    spinner: 'el-icon-loading',
    background: 'rgba(255,255,255, 0.7)',
    ...data
  });
  return loading
}
export default {
  warning,
  error,
  info,
  success,
  loading
}