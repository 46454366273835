
export default {
  date: {
    year: {
      title: '年',
      value: 1902
    },
    month: {
      title: '月',
      value: [1, 12]
    },
    day: {
      title: '日',
      value: 1
    },
    hour: {
      title: '时',
      value: [0, 23]
    },
    minute: {
      title: '分',
      value: [0, 59]
    },
    pov: {
      title: '省'
    },
    city: {
      title: '城市'
    }
  },
  version: "1.0.0",
  tips: {
    errCode: {
      400: "参数错误",
      403: "权限受限，请联系管理员",
      404: "请求链接不存在,请联系管理员",
      405: "请求方式不正确,应为POST/GET",
      500: "服务器错误,请联系管理员",
      default: "无效的请求,状态码($),请联系管理员",
      other: "未知错误，请联系管理排查"
    }
  },
  // branch:"dev",
  globalProject: 8,
  localhost: "http://guzhuanbaixun.medflying.com",
  host: "http://guzhuanbaixun.medflying.com", // 正式服记得改
  report: 'http://report1.medflying.com',//正式服记得改
  live: "ws://guzhuanbaixun.medflying.com/chat/live", // 正式服记得改
  chat: "ws://guzhuanbaixun.medflying.com/chat/client", // 正式服记得改
  urlPrefix: "/ui",
  prefix: "",  // 正式服记得改
  title: "",
  types: {
    exports: {
      zip: 'application/zip',
      xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
    },
    roles: {
      zx: "ROLE_ZHUXI",
      tl: "ROLE_TAOLUN",
      jz: "ROLE_JIANGZHE",
    },
    roleMap: {
      ROLE_ZHUXI: "会议主席",
      ROLE_TAOLUN: "讨论嘉宾",
      ROLE_JIANGZHE: "会议讲者",
    },
    meetStatus: { 0: "即将开始", 10: "直播中", 20: "已结束" },
    status: [{ label: "否", value: 0 }, { label: "是", value: 1 }],
    irAE: {
      "皮肤毒性": [
        "无",
        "斑丘疹/皮疹",
        "瘙痒",
        "大疱性皮炎/Stevens-Johnson综合征（SJS）/中毒性表皮坏死松解症（TEN）",
        "其他"
      ],
      "内分泌毒性": [
        "甲状腺功能减退",
        "甲状腺功能亢进",
        "垂体炎",
        "原发性肾上腺功能减退",
        "高血糖",
      ],
      "胰腺毒性": [
        "无症状性淀粉酶/脂肪酶升高",
        "急性胰腺炎",
        "其他",
      ],
      "骨关节与肌毒性": [
        "炎性关节炎",
        "肌炎",
        "肌痛",
        "其他",
      ],
      "神经毒性": [
        "重症肌无力（MG）",
        "格林-巴利综合征",
        "无菌性脑膜炎",
        "脑炎",
        "横断性脊髓炎",
        "其他",
      ],
      "血液毒性": [
        "自身免疫性溶血性贫血",
        "再生障碍性贫血",
        "免疫性血小板减少症",
        "获得性血友病",
        "其他",
      ],
      "眼毒性": [
        "葡萄膜炎",
        "巩膜炎",
        "其他",
      ],
      "肺毒性": [
        "免疫相关性肺炎",
        "免疫相关性肺结节病",
        "其他",
      ]
    },
    disease: {
      "肺癌(LC)": ["肺癌(LC)"],
      "乳腺癌（BC）": ['乳腺癌（BC）'],
      "前列腺癌PC": ['前列腺癌PC'],
      '多发性骨髓瘤MM': ['多发性骨髓瘤MM'],
      'MDT多学科讨论': ['MDT多学科讨论'],
      '其他': [
        '胆管癌（ICC）',
        '黑色素瘤（Mel）',
        '结直肠癌（CRC）',
        '软组织肉瘤（STS）',
        '头颈癌（H&NC)',
        '胰腺癌（PC）',
        '其他',
      ],
    }
  },
  version: "1.0.0",
  wxinterface: [
    // "startRecord",
    // "stopRecord",
    // "onVoiceRecordEnd",
    // "playVoice",
    // "pauseVoice",
    // "stopVoice",
    // "onVoicePlayEnd",
    // "uploadVoice",
    // "downloadVoice",
    // "closeWindow",
    // "scanQRCode",
    "updateAppMessageShareData",
    "onMenuShareAppMessage",
    "onMenuShareTimeline",
    "updateTimelineShareData",
  ],
  role: [{ label: '会议主席', value: 1, text: '主席位空缺' }, { label: '会议讲者', value: 2 }, { label: '讨论嘉宾', value: 3, text: '讨论位空缺' }],
  meetStatus: ['组建中', '待召开'],
  teamTypes: ['公开征集', "指定邀请"],
  scores: ['1分', "2分", '3分', '4分', '未知'],
  sexs: ['隐私', '男', '女'],
  treatments: ['术前新辅助', '术后辅助', '新辅助+辅助', '术前转化', '1L一线', '2L一线', '3L一线及以后', '其他'],//治疗线数
  medicationCycles: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,],//用药周期
  historyTreatments: ['放疗', '介入', '靶向', '化疗', '抗血管生成', '骨保护', '膀胱灌注', '其他'],//历史治疗
  curativeEffects: ['疾病进展', '疾病稳定', '部分缓解', '完全缓解', '未评估', '其他'],//疗效评估
  irAEtypes: []//irAE类型
}